import type { Dispatch, FC, SetStateAction } from 'react'

import { Tooltip } from '@mui/material'

import {
  baserowColors,
  type BaserowColor,
} from 'src/components/HubDash/lib/baserow/baserowColors'

interface ColorGridProps {
  selectedColor: BaserowColor
  setSelectedColor: Dispatch<SetStateAction<BaserowColor>>
  displayDynamicColors?: boolean
}

const ColorGrid: FC<ColorGridProps> = ({
  selectedColor,
  setSelectedColor,
  displayDynamicColors = true,
}) => {
  const appearanceColors: BaserowColor[] = [
    { name: 'white', hex: '#ffffff' },
    { name: 'black', hex: '#000000' },
    { name: 'standard', hex: '#a0aec0' },
    { name: 'dynamic', hex: '#000000' },
  ]

  return (
    <div className="grid grid-flow-col grid-cols-11 grid-rows-4 gap-2">
      {displayDynamicColors &&
        appearanceColors.map((color) => (
          <Tooltip title={color.name} key={color.name} arrow>
            <button
              key={color.name}
              className={`h-8 w-8 cursor-pointer rounded-lg ${
                color.name === selectedColor.name
                  ? 'border-4 border-indigo-500'
                  : ''
              }`}
              style={{
                background:
                  color.name === 'dynamic'
                    ? 'linear-gradient(135deg, blue 50%, red 50%)'
                    : color.hex,
              }}
              onClick={() => setSelectedColor(color)}
            />
          </Tooltip>
        ))}
      {baserowColors.map((color) => {
        // skip the color with position 0-0, no need to render them in the grid, BaseRow has a few colors that are not in the grid but are used
        if (color.position === '0-0') return null
        return (
          <Tooltip title={color.name} key={color.name} arrow>
            <button
              key={color.name}
              className={`h-8 w-8 cursor-pointer rounded-lg ${
                color.name === selectedColor.name
                  ? 'border-4 border-indigo-500'
                  : ''
              }`}
              style={{ backgroundColor: color.hex }}
              onClick={() => setSelectedColor(color)}
              data-testid={`color-grid-${color.name}`}
            />
          </Tooltip>
        )
      })}
    </div>
  )
}

export default ColorGrid
