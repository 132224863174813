import React, { useState } from 'react'

import * as IconSet from '@heroicons/react/24/outline'

import { SearchField } from 'src/components/Library/SearchField/SearchField'

import { isDarkColor } from '../BasesScreen/utils'
import { getHexForColor } from '../HubDash/lib/baserow/baserowColors'

interface IconPickerProps {
  selectedIcon: string
  setSelectedIcon: React.Dispatch<React.SetStateAction<string>>
  iconColor: string
  colorContrast?: boolean
}

const IconPicker: React.FC<IconPickerProps> = ({
  selectedIcon,
  setSelectedIcon,
  iconColor,
  colorContrast = false,
}) => {
  const [iconSearchValue, setIconSearchValue] = useState('')

  const allColorsIcons = Object.entries(IconSet).map(([name, Icon]) => ({
    name,
    icon: Icon,
  }))

  return (
    <>
      <SearchField value={iconSearchValue} onChange={setIconSearchValue} />

      <div className="flex max-h-48 flex-wrap gap-2 overflow-scroll">
        {allColorsIcons
          .filter((iconItem) =>
            iconItem.name.toLowerCase().includes(iconSearchValue.toLowerCase()),
          )
          .map((iconItem) => {
            let iconTextColor = 'text-white'

            if (
              colorContrast &&
              selectedIcon === iconItem.name &&
              !isDarkColor(iconColor)
            ) {
              iconTextColor = 'text-black'
            }
            return (
              <button
                key={iconItem.name}
                className="h-8 w-8 rounded-md bg-gray-400"
                onClick={() => {
                  if (selectedIcon === iconItem.name) {
                    setSelectedIcon('')
                  } else {
                    setSelectedIcon(iconItem.name)
                  }
                }}
                style={{
                  backgroundColor:
                    selectedIcon === iconItem.name
                      ? getHexForColor(iconColor)
                      : 'gray',
                }}
                data-testid={`icon-picker-${iconItem.name}`}
              >
                <iconItem.icon className={`h-8 w-8 ${iconTextColor}`} />
              </button>
            )
          })}
      </div>
    </>
  )
}

export default IconPicker
